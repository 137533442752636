// config.js

// 定义与流程设计相关的术语变量
export const terms = {
  // 节点标签  
  "node1": "问题思考与分析",
  "node2": [
    "问题定义",
    "背景分析", 
    "信息收集",
    "原因分析",
    "影响评估", 
    "风险识别",
    "分析与诊断",
    "解决方案生成",
    "解决方案评估",
    "总结",
    "延伸思考"  
  ],
  // 标记需要 detailFlag 的 node2 项
  "node2ComplexItems": [
    "背景分析",
    "原因分析", 
    "影响评估",
    "风险识别", 
    "分析与诊断",
    "解决方案生成",
    "解决方案评估", 
    "延伸思考"
  ],
  "node3": "子步骤",
  "node4": "具体阶段",
  "node5": "具体阶段分解",
    // 通用术语
  "mainStructure": "流程设计",
  "title": "标题",
  "outline": "大纲",
  "content": "内容",
  "detail": "详细内容",
  "type": "类型",
  "detailFlag": "detail", // 标识是否需要生成详细内容的标志
    // 消息类型
  "sectionDetailType": "sectionDetail"
};

// **将 fixedDescriptions 改为导出**
export const fixedDescriptions = {
  "问题定义": "明确需要解决的问题，定义问题的范围和影响，确保所有相关方对问题有一致的理解。",
  "背景分析": "分析问题产生的背景，包括历史背景、现状描述、相关环境因素等，以全面了解问题的根源。",
  "信息收集": "收集与问题相关的所有必要信息和数据，包括定量和定性的资料，以支持后续分析。",
  "原因分析": "识别问题的根本原因，使用各种分析工具和方法，如因果分析、鱼骨图、5为什么等，深入探讨问题背后的因素。",
  "影响评估": "评估问题对各方面的影响，包括对组织、人员、流程、财务等方面的具体影响。",
  "风险识别": "识别与问题相关的潜在风险，分析风险的可能性和影响，为制定应对策略提供依据。",
  "分析与诊断": "综合前述分析，深入诊断问题的本质，明确问题的核心所在，形成系统化的理解。",
  "解决方案生成": "基于分析结果，开发可行的解决方案，提出多种方案供选择，并初步评估其可行性和潜在影响。",
  "解决方案评估": "对生成的解决方案进行详细评估，考虑其优缺点、实施成本、时间、资源需求等，选择最优方案。",
  "总结": "对整个分析过程和结果进行总结，概括主要发现和结论，确保问题的核心得到清晰表达。",
  "延伸思考": "基于总结的结果，进行进一步的思考和探讨，探索潜在的改进措施、未来的发展方向或相关的附加问题。",
  "子步骤": "每个主要步骤下的子步骤名称，用于进一步细化流程。",
  "具体阶段": "子步骤中的具体阶段或任务，用于详细描述每个子步骤的执行过程。",
  "具体阶段分解": "每个具体阶段的详细内容，包括操作指南、所需资源、预期结果等。",
  "流程设计": "整个问题思考与分析流程的设计名称，用于整体规划和管理流程。",
  "标题": "标题",
  "大纲": "流程的整体大纲和结构。",
  "内容": "流程中各部分的具体内容。",
  "详细内容": "流程中各部分的详细描述和说明。",
  "类型": "类型",
  "detail": "是否需要生成页面的详细内容",
  "sectionDetailType": "环节内容的消息类型"
};

// 系统角色提示词
export const systemRolePrompt = `你是一位经验丰富的问题解决与分析专家，擅长通过系统化的方法帮助用户思考和剖析问题，最终得出总结和延伸思考。请确保以下几点:\n1. 流程结构清晰，逻辑严密。\n2. 包含详细的步骤和说明，确保用户能够理解并应用。\n3. 输出内容严格遵循用户提供的 JSON 结构和字段名称，确保内容完整、条理清晰。\n4. 每个步骤和子步骤应包含明确的目标、预期成果以及具体的执行步骤。\n5. 在设计上以用户为中心，确保可读性和易用性。\n\n你负责根据这些指导原则，支持流程设计的生成、调整和细节补充。`;

// 在导出之前，先将对象赋值给一个变量
const defaultConfig = {
  terms,
  fixedDescriptions,
  systemRolePrompt,
};

// 然后导出这个变量
export default defaultConfig;